.svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .centered-svg {
        max-width: 100%;
        max-height: 100%;
      }
      
  }
  
