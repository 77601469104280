.service-details-section h4 {
    font-size: 40px;
    color: #fff;
    margin-bottom: 30px;
    margin-top: 30px;
}

@media(max-width:767px){
    .service-details-section h4 {
        font-size: 30px;
        margin-bottom: 15px;
        margin-top: 20px;
    }
}

.panorama-container {
    position: relative;
    width: 100%;
    height: 100vh;
  }
  
  .overlay-text {
    position: absolute;
    top: 20px; /* Adjust the top position */
    left: 20px; /* Adjust the left position */
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
    font-size: 18px;
    z-index: 1; /* Ensure it appears above the panorama viewer */
  }
  